<template>
    <v-sheet>
        <div v-if="isHead">
            <product-view-content-detail v-bind="{ type, _category: _parentCategory }"/>
            <product-view-content-detail v-bind="{ type, _category: _parentCategory, _seller: product?._seller }"/>
        </div>
        <div v-else>
            <product-view-content-detail v-bind="{ type, _category: _parentCategory, _seller: product?._seller }"/>
            <product-view-content-detail v-bind="{ type, _category: _parentCategory }"/>
        </div>
    </v-sheet>
</template>

<script>
import { CATEGORY_DETAILS_TYPES } from "@/assets/variables";
import ProductViewContentDetail from "./product-view-content-detail.vue";

export default {
    components: {
        ProductViewContentDetail,
    },
    props: {
        type: { type: String, default: CATEGORY_DETAILS_TYPES.HEAD.value },
        product: { type: Object, default: () => ({}) },
    },
    computed: {
        isHead() {
            return this.type === CATEGORY_DETAILS_TYPES.HEAD.value;
        },
        _parentCategory() {
            return this.product?.category?._parents[0] || this.product?._category;
        }
    }
}
</script>

<style>

</style>