<template>
    <div v-if="categoryDetail.body" v-html="categoryDetail.body"/>
</template>

<script>
import api from "@/api";
import { CATEGORY_DETAILS_TYPES } from "@/assets/variables";
import { initCategoryDetail } from "@/components/console/shop/category-details/category-detail-form.vue";
export default {
    props: {
        _seller: { type: String, default: null },
        _category: { type: String, default: null },
        type: { type: String, default: CATEGORY_DETAILS_TYPES.HEAD.value },
    },
    data: () => ({
        categoryDetail: {
            body: null
        }
    }),
    computed: {
        params() {
            const { _seller, _category, type } = this;
            return { _seller, _category, type, enabled: true };
        },
    },
    methods: {
        async search() {
            if(!this._category) return;
            try {
                const { categoryDetails } = await api.v1.shop.categoryDetails.gets({ params: this.params });

                this.categoryDetail = initCategoryDetail(categoryDetails[0]);
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    mounted() {
        this.search();
    },
};
</script>

<style>
</style>